import axios from '@/common/axios'
import qs from 'qs'

export function addFinishedProductAudit (data) {
  return axios({
    method: 'post',
    url: '/quality/productAudit/add',
    data: qs.stringify(data)
  })
}

export function deleteFinishedProductAudit (id) {
  return axios({
    method: 'delete',
    url: '/quality/productAudit/delete/' + id
  })
}

export function updateFinishedProductAudit (data) {
  return axios({
    method: 'put',
    url: '/quality/productAudit/update',
    data: qs.stringify(data)
  })
}

export function selectFinishedProductAuditInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/productAudit/info/' + id
  })
}

export function selectFinishedProductAuditList (query) {
  return axios({
    method: 'get',
    url: '/quality/productAudit/list',
    params: query
  })
}

export function finishedProductAuditReviewSC (data) {
  return axios({
    method: 'put',
    url: '/quality/productAudit/updateReview1',
    data: qs.stringify(data)
  })
}

export function finishedProductAuditReviewZL (data) {
  return axios({
    method: 'put',
    url: '/quality/productAudit/updateReview2',
    data: qs.stringify(data)
  })
}

export function finishedProductAuditReviewGD (data) {
  return axios({
    method: 'put',
    url: '/quality/productAudit/updateReview3',
    data: qs.stringify(data)
  })
}
