<template>
  <div id="finishedProductAudit">
    <el-dialog
      :title="finishedProductAuditFormTitle"
      width="1200px"
      :visible.sync="finishedProductAuditDialogVisible"
      :close-on-click-modal="false"
      @close="finishedProductAuditDialogClose"
    >
      <div id="pdfDom">
        <h1 style="text-align: center">
          成品放行审核单
        </h1>
        <el-form
          ref="finishedProductAuditFormRef"
          :model="finishedProductAuditForm"
          :rules="finishedProductAuditFormRules"
          label-position="right"
          label-width="100px"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="编号" prop="formNo">
                <el-input
                  v-model="finishedProductAuditForm.formNo"
                  placeholder="请输入编号"
                  clearable
                  :disabled="finishedProductAuditFormTitle !== '新增成品放行审核单'
                    && finishedProductAuditFormTitle !== '修改成品放行审核单'
                    && finishedProductAuditFormTitle !== '成品放行审核单详情'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="品名" prop="productName">
                <el-input
                  v-model="finishedProductAuditForm.productName"
                  placeholder="请输入品名"
                  clearable
                  :disabled="finishedProductAuditFormTitle !== '新增成品放行审核单'
                    && finishedProductAuditFormTitle !== '修改成品放行审核单'
                    && finishedProductAuditFormTitle !== '成品放行审核单详情'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="批号" prop="batchNo">
                <el-input
                  v-model="finishedProductAuditForm.batchNo"
                  placeholder="请输入批号"
                  clearable
                  :disabled="finishedProductAuditFormTitle !== '新增成品放行审核单'
                    && finishedProductAuditFormTitle !== '修改成品放行审核单'
                    && finishedProductAuditFormTitle !== '成品放行审核单详情'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="规格" prop="spec">
                <el-input
                  v-model="finishedProductAuditForm.spec"
                  placeholder="请输入规格"
                  clearable
                  :disabled="finishedProductAuditFormTitle !== '新增成品放行审核单'
                    && finishedProductAuditFormTitle !== '修改成品放行审核单'
                    && finishedProductAuditFormTitle !== '成品放行审核单详情'"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="生产审核">
            <vxe-table
              ref="productionTable"
              border
              resizable
              keep-source
              :data="productionList"
              :scroll-y="{enabled: false}"
              :edit-config="{trigger: 'click', mode: 'cell'}"
            >
              <vxe-table-column
                field="auditItem"
                title="审核项目"
                width="150"
                :content-render="{name: '$input'}"
              />
              <vxe-table-column
                field="standard"
                title="审核标准"
                :content-render="{name: '$input'}"
              />
              <vxe-table-column field="result" title="结果" width="150">
                <template v-slot="{row,column}">
                  <vxe-radio-group v-model="row[column.property]">
                    <vxe-radio :label="1" content="符合" />
                    <vxe-radio :label="0" content="不符合" />
                  </vxe-radio-group>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
          <el-row>
            <el-col :span="8">
              <el-form-item label="结论" prop="conclusion1">
                <el-radio-group
                  v-model="finishedProductAuditForm.conclusion1"
                  :disabled="finishedProductAuditFormTitle !== '成品放行审核单生产审核'
                    && finishedProductAuditFormTitle !== '成品放行审核单详情'"
                >
                  <el-radio :label="1">
                    符合规定
                  </el-radio>
                  <el-radio :label="2">
                    不符合规定
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                v-if="finishedProductAuditFormTitle === '成品放行审核单详情'"
                label="生产审核人"
                prop="reviewer1"
              >
                <el-input
                  v-model="finishedProductAuditForm.reviewer1"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                v-if="finishedProductAuditFormTitle === '成品放行审核单详情'"
                label="生产审核日期"
                prop="reviewDate1"
              >
                <el-input
                  v-model="finishedProductAuditForm.reviewDate1"
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="质量审核">
            <vxe-table
              ref="qualityTable"
              border
              resizable
              keep-source
              :data="qualityList"
              :scroll-y="{enabled: false}"
              :edit-config="{trigger: 'click', mode: 'cell'}"
            >
              <vxe-table-column
                field="auditItem"
                title="审核项目"
                width="210"
                :content-render="{name: '$input'}"
              />
              <vxe-table-column
                field="standard"
                title="审核标准"
                :content-render="{name: '$input'}"
              />
              <vxe-table-column field="result" title="结果" width="150">
                <template v-slot="{row,column}">
                  <vxe-radio-group v-model="row[column.property]">
                    <vxe-radio :label="1" content="符合" />
                    <vxe-radio :label="0" content="不符合" />
                  </vxe-radio-group>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
          <el-row>
            <el-col :span="8">
              <el-form-item label="结论" prop="conclusion2">
                <el-radio-group
                  v-model="finishedProductAuditForm.conclusion2"
                  :disabled="finishedProductAuditFormTitle !== '成品放行审核单质量审核'
                    && finishedProductAuditFormTitle !== '成品放行审核单详情'"
                >
                  <el-radio :label="1">
                    符合规定
                  </el-radio>
                  <el-radio :label="2">
                    不符合规定
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                v-if="finishedProductAuditFormTitle === '成品放行审核单详情'"
                label="质量审核人"
                prop="reviewer2"
              >
                <el-input
                  v-model="finishedProductAuditForm.reviewer2"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                v-if="finishedProductAuditFormTitle === '成品放行审核单详情'"
                label="质量审核日期"
                prop="reviewDate2"
              >
                <el-input
                  v-model="finishedProductAuditForm.reviewDate2"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="管代结论" prop="conclusion3">
                <el-radio-group
                  v-model="finishedProductAuditForm.conclusion3"
                  :disabled="finishedProductAuditFormTitle !== '成品放行审核单管代结论'
                    && finishedProductAuditFormTitle !== '成品放行审核单详情'"
                >
                  <el-radio :label="1">
                    符合规定，同意放行
                  </el-radio>
                  <el-radio :label="2">
                    不符合规定，不同意放行
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                v-if="finishedProductAuditFormTitle === '成品放行审核单详情'"
                label="管代审核人"
                prop="reviewer3"
              >
                <el-input
                  v-model="finishedProductAuditForm.reviewer3"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                v-if="finishedProductAuditFormTitle === '成品放行审核单详情'"
                label="管代审核日期"
                prop="reviewDate3"
              >
                <el-input
                  v-model="finishedProductAuditForm.reviewDate3"
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="finishedProductAuditDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="finishedProductAuditFormSubmit">
          确 定
        </el-button>
        <el-button v-if="finishedProductAuditFormTitle === '成品放行审核单详情'" type="primary" @click="getPdf">
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['FINISHED_PRODUCT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="finishedProductAuditPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="productName" label="品名" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="conclusion1" label="生产结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion1 == 1">符合规定</span>
          <span v-if="scope.row.conclusion1 == 2">不符合规定</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer1" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate1">{{ scope.row.reviewDate1.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="conclusion2" label="质量结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion2 == 1">符合规定</span>
          <span v-if="scope.row.conclusion2 == 2">不符合规定</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer2" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate2">{{ scope.row.reviewDate2.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="conclusion3" label="管代结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion3 == 1">符合规定，同意放行</span>
          <span v-if="scope.row.conclusion3 == 2">不符合规定，不同意放行</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer3" label="管理者代表" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate3">{{ scope.row.reviewDate3.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['FINISHED_PRODUCT_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['FINISHED_PRODUCT_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['FINISHED_PRODUCT_SC']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReviewSC(scope.$index, scope.row)"
          >
            生产审核
          </el-button>
          <el-button
            v-if="checkPermission(['FINISHED_PRODUCT_ZL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReviewZL(scope.$index, scope.row)"
          >
            质量审核
          </el-button>
          <el-button
            v-if="checkPermission(['FINISHED_PRODUCT_GD']) && scope.row.status === 3"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReviewGD(scope.$index, scope.row)"
          >
            管代结论
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="finishedProductAuditPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addFinishedProductAudit,
  deleteFinishedProductAudit,
  updateFinishedProductAudit,
  selectFinishedProductAuditInfo,
  selectFinishedProductAuditList,
  finishedProductAuditReviewSC,
  finishedProductAuditReviewZL,
  finishedProductAuditReviewGD
} from '@/api/quality/finishedProductAudit'
import moment from 'moment'
import html2PDF from 'jspdf-html2canvas'
import { Loading } from 'element-ui'

export default {
  data () {
    return {
      finishedProductAuditDialogVisible: false,
      finishedProductAuditFormTitle: '',
      finishedProductAuditForm: {
        id: '',
        formNo: '',
        productName: '',
        batchNo: '',
        spec: '',
        conclusion1: '',
        conclusion2: '',
        conclusion3: '',
        status: '',
        productionJson: '',
        qualityJson: '',
        taskId: '',
        reviewer1: '',
        reviewDate1: '',
        reviewer2: '',
        reviewDate2: '',
        reviewer3: '',
        reviewDate3: ''
      },
      finishedProductAuditFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      finishedProductAuditPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      },
      productionList: [
        { auditItem: '1.生产指令及主配方', standard: '1.物料领用数量是否符合指令要求\n2.生产配方是否与工艺规程相符',  result: '', type: 1 },
        { auditItem: '2.生产用物料', standard: '投料量与配料单要求一致，投料次序正确，工艺参数正常',  result: '', type: 1 },
        { auditItem: '3.批生产记录', standard: '1.记录齐全、书写正确、数据完整、有操作人、复核人签名\n2.生产符合工艺要求，生产状态、清场等均符合规定',  result: '', type: 1 },
        { auditItem: '4.物料平衡', standard: '1.遵循物料平衡原则\n2.各工序物料平衡结果符合标准',  result: '', type: 1 },
        { auditItem: '5.偏差处理', standard: '生产偏差是否执行偏差处理程序，处理结果是否符合要求',  result: '', type: 1 }
      ],
      qualityList: [
        { auditItem: '1.监控记录及取样记录审核', standard: '1.记录齐全、书写正确、数据完整，有监控人签名\n2.监控项目齐全，结果符合规定，取样单及取样数量正确',  result: '', type: 2 },
        { auditItem: '2.偏差处理', standard: '检验偏差是否执行调查程序，处理结果是否符合要求',  result: '', type: 2 },
        { auditItem: '3.进货、过程及成品检验审核', standard: '1.记录齐全、书写正确、数据完整，有操作人、复核人签名\n2.检验报告单项目及结果应符合标准\n3.检验报告单有批准人签字',  result: '', type: 2 },
        { auditItem: '4.其他要求', standard: '产品说明书、标签及其版本符合规定要求',  result: '', type: 2 }
      ]
    }
  },
  created () {
    selectFinishedProductAuditList(this.searchForm).then(res => {
      this.finishedProductAuditPage = res
    })
  },
  methods: {
    finishedProductAuditDialogClose () {
      this.$refs.finishedProductAuditFormRef.resetFields()
      this.productionList = [
        { auditItem: '1.生产指令及主配方', standard: '1.物料领用数量是否符合指令要求\n2.生产配方是否与工艺规程相符',  result: '', type: 1 },
        { auditItem: '2.生产用物料', standard: '投料量与配料单要求一致，投料次序正确，工艺参数正常',  result: '', type: 1 },
        { auditItem: '3.批生产记录', standard: '1.记录齐全、书写正确、数据完整、有操作人、复核人签名\n2.生产符合工艺要求，生产状态、清场等均符合规定',  result: '', type: 1 },
        { auditItem: '4.物料平衡', standard: '1.遵循物料平衡原则\n2.各工序物料平衡结果符合标准',  result: '', type: 1 },
        { auditItem: '5.偏差处理', standard: '生产偏差是否执行偏差处理程序，处理结果是否符合要求',  result: '', type: 1 }
      ],
      this.qualityList = [
        { auditItem: '1.监控记录及取样记录审核', standard: '1.记录齐全、书写正确、数据完整，有监控人签名\n2.监控项目齐全，结果符合规定，取样单及取样数量正确',  result: '', type: 2 },
        { auditItem: '2.偏差处理', standard: '检验偏差是否执行调查程序，处理结果是否符合要求',  result: '', type: 2 },
        { auditItem: '3.进货、过程及成品检验审核', standard: '1.记录齐全、书写正确、数据完整，有操作人、复核人签名\n2.检验报告单项目及结果应符合标准\n3.检验报告单有批准人签字',  result: '', type: 2 },
        { auditItem: '4.其他要求', standard: '产品说明书、标签及其版本符合规定要求',  result: '', type: 2 }
      ]
    },
    finishedProductAuditFormSubmit () {
      if (this.finishedProductAuditFormTitle === '成品放行审核单详情') {
        this.finishedProductAuditDialogVisible = false
        return
      }
      this.$refs.finishedProductAuditFormRef.validate(async valid => {
        if (valid) {
          if (this.finishedProductAuditFormTitle === '新增成品放行审核单') {
            this.finishedProductAuditForm.id = ''
            this.finishedProductAuditForm.status = 1
            await addFinishedProductAudit(this.finishedProductAuditForm)
          } else if (this.finishedProductAuditFormTitle === '修改成品放行审核单') {
            await updateFinishedProductAudit(this.finishedProductAuditForm)
          } else if (this.finishedProductAuditFormTitle === '成品放行审核单生产审核') {
            this.finishedProductAuditForm.status = 2
            this.finishedProductAuditForm.productionJson = JSON.stringify(this.$refs.productionTable.getTableData().tableData)
            await finishedProductAuditReviewSC(this.finishedProductAuditForm)
          } else if (this.finishedProductAuditFormTitle === '成品放行审核单质量审核') {
            this.finishedProductAuditForm.status = 3
            this.finishedProductAuditForm.qualityJson = JSON.stringify(this.$refs.qualityTable.getTableData().tableData)
            await finishedProductAuditReviewZL(this.finishedProductAuditForm)
          } else if (this.finishedProductAuditFormTitle === '成品放行审核单管代结论') {
            this.finishedProductAuditForm.status = 4
            await finishedProductAuditReviewGD(this.finishedProductAuditForm)
          }
          this.finishedProductAuditPage = await selectFinishedProductAuditList(this.searchForm)
          this.finishedProductAuditDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.finishedProductAuditFormTitle = '新增成品放行审核单'
      this.finishedProductAuditDialogVisible = true
      this.finishedProductAuditForm.formNo = 'FX' + moment().format('YYYYMMDD') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFinishedProductAudit(row.id)
        if (this.finishedProductAuditPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.finishedProductAuditPage = await selectFinishedProductAuditList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.finishedProductAuditFormTitle = '修改成品放行审核单'
      this.finishedProductAuditDialogVisible = true
      this.selectFinishedProductAuditInfoById(row.id)
    },
    handleReviewSC (index, row) {
      this.finishedProductAuditFormTitle = '成品放行审核单生产审核'
      this.finishedProductAuditDialogVisible = true
      this.selectFinishedProductAuditInfoById(row.id)
    },
    handleReviewZL (index, row) {
      this.finishedProductAuditFormTitle = '成品放行审核单质量审核'
      this.finishedProductAuditDialogVisible = true
      this.selectFinishedProductAuditInfoById(row.id)
    },
    handleReviewGD (index, row) {
      this.finishedProductAuditFormTitle = '成品放行审核单管代结论'
      this.finishedProductAuditDialogVisible = true
      this.selectFinishedProductAuditInfoById(row.id)
    },
    handleInfo (index, row) {
      this.finishedProductAuditFormTitle = '成品放行审核单详情'
      this.finishedProductAuditDialogVisible = true
      this.selectFinishedProductAuditInfoById(row.id)
    },
    selectFinishedProductAuditInfoById (id) {
      selectFinishedProductAuditInfo(id).then(res => {
        this.finishedProductAuditForm.id = res.id
        this.finishedProductAuditForm.formNo = res.formNo
        this.finishedProductAuditForm.productName = res.productName
        this.finishedProductAuditForm.batchNo = res.batchNo
        this.finishedProductAuditForm.spec = res.spec
        this.finishedProductAuditForm.conclusion1 = res.conclusion1
        this.finishedProductAuditForm.conclusion2 = res.conclusion2
        this.finishedProductAuditForm.conclusion3 = res.conclusion3
        this.finishedProductAuditForm.status = res.status
        this.finishedProductAuditForm.taskId = res.taskId
        this.finishedProductAuditForm.reviewer1 = res.reviewer1
        this.finishedProductAuditForm.reviewDate1 = res.reviewDate1
        this.finishedProductAuditForm.reviewer2 = res.reviewer2
        this.finishedProductAuditForm.reviewDate2 = res.reviewDate2
        this.finishedProductAuditForm.reviewer3 = res.reviewer3
        this.finishedProductAuditForm.reviewDate3 = res.reviewDate3
        if (res.productionList.length > 0) {
          this.productionList = res.productionList
        }
        if (res.qualityList.length > 0) {
          this.qualityList = res.qualityList
        }
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFinishedProductAuditList(this.searchForm).then(res => {
        this.finishedProductAuditPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFinishedProductAuditList(this.searchForm).then(res => {
        this.finishedProductAuditPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFinishedProductAuditList(this.searchForm).then(res => {
        this.finishedProductAuditPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['FINISHED_PRODUCT_SC']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['FINISHED_PRODUCT_ZL']) && row.status === 2) {
        return 'review'
      } else if (this.checkPermission(['FINISHED_PRODUCT_GD']) && row.status === 3) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `成品放行审核单${this.finishedProductAuditForm.formNo}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
